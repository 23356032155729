
import { computed, defineComponent, onMounted, ref } from "vue";
// import DarkModeSwitcher from "@/components/dark-mode-switcher/Main.vue";
import { mapActions } from 'vuex';
import CloudFun from '@cloudfun/core';
import { v1 as uuid } from "uuid";

export default defineComponent({
  components: {
    // DarkModeSwitcher,
  },
  setup() {
    const captchaToken = ref(uuid());
    const captchaUrl = computed(() => `${process.env.VUE_APP_BACKEND_URL}/api/captcha?length=4&token=${captchaToken.value}`);
    onMounted(() => {
      cash("body")
        .removeClass("app")
        .removeClass("error-page")
        .addClass("login");
      cash("body")[0].click();
    });
    return {
      account: '',
      password: '',
      captcha: '',
      captchaToken,
      captchaUrl,
      rememberMe: false
    }
  },
  methods: {
    ...mapActions(['login']),
    formSubmit() {
      this.login({ account: this.account, password: this.password, rememberMe: this.rememberMe, captcha: this.captcha, token: this.captchaToken }).then(
        () => this.$router.push('/'),
        failure => {
          this.$user = CloudFun.guest;
          CloudFun.send('warning', failure.message || '帳號或密碼錯誤');
        }
      )
    },
    reloadCaptcha() {
      this.captchaToken = uuid();
    }
  },
});
